/* @font-face {
  font-family: "Roboto Slab";
  src: url("./assets/fonts/RobotoSlab-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
} */

/* @font-face {
  font-family: "Roboto Slab";
  src: url("./assets/fonts/RobotoSlab-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
} */

@font-face {
  font-family: "Roboto Slab";
  src: url("./assets/fonts/RobotoSlab-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TT Hoves Pro Trial";
  src: url("./assets/fonts/TT\ Hoves\ Pro\ Trial\ Regular.ttf")
    format("truetype");
  font-style: normal;
}
